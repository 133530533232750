import {
  TextInput,
  SimpleForm,
  SelectInput,
  List,
  useRecordContext,
  useListContext,
  useCreate,
  useRefresh,
  useNotify,
  Toolbar,
  SaveButton,
  NumberInput,
  TextField,
  Datagrid,
  SelectArrayInput,
  ReferenceArrayInput,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  FormDataConsumer,
  DeleteButton,
  SimpleShowLayout,
  ReferenceField,
  ReferenceInput,
  useGetOne,
  AutocompleteInput,
  FunctionField,
} from 'react-admin'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import Typography from '@mui/material/Typography'

import { requiredArray, validateRequired } from '../../utils/validate'
import BookToolbar from '../../components/BookToolbar'
import GridContainer from '../../components/GridContainer'
import useUpdateManyDataProvider from '../../hooks/useUpdateManyDataProvider'

const grid = 8
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 1000,
  margin: '0 auto',
})

const DISPLAY_TYPES = [
  { id: 'two_rows', name: 'Two rows' },
  { id: 'five_columns_three_rows', name: 'Five columns with three rows' },
]

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
})

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DatagridSortable = props => {
  const [items, setItems] = useState([])
  const updateDataProvider = useUpdateManyDataProvider()
  const { data } = useListContext()
  const refresh = useRefresh()
  useEffect(() => {
    if (!items.length && data) {
      setItems(
        Object.keys(data)
          .filter(x => x !== 'undefined' && !!x)
          .map(key => data[key])
          .sort((a, b) => a.position - b.position),
      )
    }
  }, [items, data])

  useEffect(() => {
    if (data) {
      setItems(
        Object.keys(data)
          .filter(x => x !== 'undefined' && !!x)
          .map(key => data[key])
          .sort((a, b) => a.position - b.position),
      )
    }
  }, [data])

  const updateOrderSections = useMemo(
    () => newItems => {
      updateDataProvider(
        'homepage-sections',
        { data: newItems },
        {
          onSuccess: {
            notification: { body: 'Order section updated', level: 'info' },
          },
          onFailure: {
            notification: {
              body: 'Error: Section not updated',
              level: 'warning',
            },
          },
        },
      )
    },
    [updateDataProvider],
  )

  const onDragEnd = useCallback(
    result => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const newItems = reorder(
        items,
        result.source.index,
        result.destination.index,
      )

      setItems(newItems)
      Promise.all([
        updateOrderSections(
          newItems.map((item, index) => ({ ...item, position: index })),
        ),
      ]).then(refresh())
    },
    [items, refresh, updateOrderSections],
  )

  return props.loading ? (
    <CircularProgress />
  ) : (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {items.map((item, index) => (
              <Draggable
                key={item.id}
                draggableId={String(item.id)}
                index={index}>
                {(provided, snapshot) => (
                  <>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}>
                      <Grid container={true} spacing={1}>
                        <Grid item xs={2}>
                          {item.position}
                        </Grid>
                        <Grid item xs={7}>
                          <ReferenceField
                            reference="homepage-default-sections"
                            record={item}
                            source="defaultSectionId">
                            <FunctionField
                              source="name"
                              render={record =>
                                `${record.name} | ${record.key}`
                              }
                            />
                          </ReferenceField>
                        </Grid>
                        <Grid item xs={2}>
                          {
                            DISPLAY_TYPES.find(
                              dis => dis.id === item.displayType,
                            )?.name
                          }
                        </Grid>
                        <Grid item xs={1}>
                          <DeleteButton
                            record={item}
                            resource="homepage-sections"
                            redirect={false}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const SectionAssignToolbar = () => {
  const [create] = useCreate()
  const refresh = useRefresh()
  const notify = useNotify()

  const { id } = useParams()
  const { getValues } = useFormContext()

  const onSubmit = async e => {
    e.preventDefault()
    let { position, displayType, sectionDefault } = getValues()
    const homepageSegmentId = parseInt(id)

    create(
      'homepage-sections',
      {
        data: {
          homepageSegmentId,
          position,
          displayType,
          defaultSectionId: sectionDefault,
        },
      },
      {
        onSuccess: () => {
          refresh()
          notify('Section create successfully')
        },
        onError: error => {
          notify(`${error.message}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <Toolbar>
      <SaveButton type="button" onClick={onSubmit} />
    </Toolbar>
  )
}

const ShuffleGroupAssignToolbar = () => {
  const [create] = useCreate()
  const refresh = useRefresh()
  const notify = useNotify()

  const { id } = useParams()
  const { getValues } = useFormContext()

  const onSubmit = async e => {
    e.preventDefault()

    const { groupName, sectionIds } = getValues()
    const homepageSegmentId = parseInt(id)

    create(
      'homepage-shuffle-groups',
      {
        data: {
          homepageSegmentId,
          name: groupName,
          sectionIds,
        },
      },
      {
        onSuccess: () => {
          refresh()
          notify('Shuffle group create successfully')
        },
        onError: error => {
          notify(`${error.message}`, { type: 'error' })
        },
      },
    )
  }

  return (
    <Toolbar>
      <SaveButton type="button" onClick={onSubmit} />
    </Toolbar>
  )
}

const RenderSectionDefault = ({ sectionDefault }) => {
  const { data, loading, error } = useGetOne('homepage-default-sections', {
    id: sectionDefault,
  })
  if (loading) return <div>Loading section...</div>
  if (error) return <div>Error loading section</div>

  if (!data) {
    return <></>
  }

  return (
    <SimpleShowLayout>
      <TextField
        name="sectionName"
        source="sectionName"
        defaultValue={data.name}
      />
      <TextField source="sectionKey" defaultValue={data.key} />
      <TextField source="recommendType" defaultValue={data.recommendType} />
    </SimpleShowLayout>
  )
}

const SectionAssignAside = () => {
  const record = useRecordContext()
  return (
    <Box sx={{ margin: '0.5rem', width: 400 }}>
      <Typography variant="body1">Create a section</Typography>
      <SimpleForm toolbar={<SectionAssignToolbar />}>
        <NumberInput source="position" />
        <SelectInput
          source="displayType"
          choices={DISPLAY_TYPES}
          fullWidth
          variant="standard"
        />
        <ReferenceInput
          reference="homepage-default-sections"
          source="sectionDefault"
          filter={{
            type: record.type,
          }}>
          <AutocompleteInput
            optionText="name"
            options={{
              fullWidth: true,
            }}
            variant="standard"
            filterToQuery={searchText => ({
              name: { $iLike: `%${searchText}%` },
              type: record.type,
            })}
          />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData }) => {
            if (formData && formData.sectionDefault) {
              return (
                <RenderSectionDefault
                  sectionDefault={formData.sectionDefault}
                />
              )
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Box>
  )
}

const ShuffleGroupAssignAside = () => {
  return (
    <Box sx={{ margin: '0.5rem', width: 400 }}>
      <Typography variant="body1">Create a shuffle group</Typography>
      <SimpleForm toolbar={<ShuffleGroupAssignToolbar />}>
        <TextInput name="groupName" source="groupName" />
        <ReferenceArrayInput
          label="Sections"
          source="sectionIds"
          reference="homepage-sections"
          filter={{ homepageSegmentId: parseInt(useParams().id) }}
          validate={requiredArray('Section ids')}>
          <SelectArrayInput
            optionText="defaultSection.name"
            fullWidth
            variant="standard"
            label="Sections"
            required
          />
        </ReferenceArrayInput>
      </SimpleForm>
    </Box>
  )
}

const HomepageSegmentForm = props => {
  const record = useRecordContext()
  const segmentId = record?.id
  const TYPES = [
    { id: 'homepage', name: 'Homepage', disabled: props.editing },
    { id: 'podcourse', name: 'Podcourse', disabled: props.editing },
  ]
  const SEGMENT_TYPES = [
    { id: 'guest_user', name: 'Guest Users', disabled: props.editing },
    { id: 'free_member', name: 'Free Members', disabled: props.editing },
    {
      id: 'premium_with_credit',
      name: 'Premium with credits',
      disabled: props.editing,
    },
    {
      id: 'premium_without_credit',
      name: 'Premium without credits',
      disabled: props.editing,
    },
  ]

  return (
    <SimpleForm {...props} toolbar={<BookToolbar />} redirect="list">
      <Card style={{ width: '100%', marginBottom: '10px' }}>
        <CardHeader title="Segment Info" />
        <CardContent>
          <GridContainer>
            <Grid item xs={6}>
              <TextInput
                source="name"
                fullWidth
                variant="standard"
                validate={[validateRequired('Name')]}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                source="segmentType"
                choices={SEGMENT_TYPES}
                fullWidth
                variant="standard"
                required
                validate={validateRequired('Segment Type')}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectInput
                source="type"
                choices={TYPES}
                fullWidth
                variant="standard"
                required
                validate={validateRequired('Type')}
              />
            </Grid>
          </GridContainer>
        </CardContent>
      </Card>
      {segmentId && (
        <>
          <Card style={{ width: '100%', marginBottom: '10px' }}>
            <CardHeader title="Section List" />
            <CardContent>
              <GridContainer>
                <Grid item xs={12}>
                  <List
                    {...props}
                    resource="homepage-sections"
                    basePath="/homepage-sections"
                    perPage={1000}
                    pagination={null}
                    filters={null}
                    aside={<SectionAssignAside />}
                    filter={{
                      homepageSegmentId: segmentId,
                    }}
                    actions={null}>
                    <DatagridSortable
                      bulkActionButtons={false}
                      rowClick={false}
                      {...props}
                    />
                  </List>
                </Grid>
              </GridContainer>
            </CardContent>
          </Card>
          <Card style={{ width: '100%' }}>
            <CardHeader title="Shuffle Groups" />
            <CardContent>
              <GridContainer>
                <Grid item xs={12}>
                  <List
                    {...props}
                    resource="homepage-shuffle-groups"
                    placeholder="Shuffle Groups"
                    basePath="/homepage-shuffle-groups"
                    perPage={1000}
                    pagination={null}
                    filters={null}
                    aside={<ShuffleGroupAssignAside />}
                    filter={{
                      homepageSegmentId: segmentId,
                    }}
                    exporter={null}
                    action={null}>
                    <Datagrid rowClick={false}>
                      <TextField source="name" />
                      <ReferenceArrayField
                        reference="homepage-sections"
                        source="sectionIds"
                        label="Sections">
                        <SingleFieldList>
                          <ChipField source="defaultSection.name" />
                        </SingleFieldList>
                      </ReferenceArrayField>
                      <ReferenceArrayField
                        reference="homepage-sections"
                        source="sectionIds"
                        label="Positions">
                        <SingleFieldList>
                          <ChipField source="position" />
                        </SingleFieldList>
                      </ReferenceArrayField>
                    </Datagrid>
                  </List>
                </Grid>
              </GridContainer>
            </CardContent>
          </Card>
        </>
      )}
    </SimpleForm>
  )
}

export default HomepageSegmentForm
